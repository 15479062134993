import {
    Login,
    NotFound,
    System,
    User,
    Admin,
    Role,
    Individual,
    IndexStrategy,
    IndexCourse,
    IndexData,
    IndexPartner,
    IndexPartnerSmall,
    WalkInIntroduction,
    WalkInCulture,
    WalkInTeam,
    HealthyIntroduction,
    HealthyCover,
    HealthyAdvantage,
    CrowdPioneer,
    CrowdPolicy,
    CrowdBrand,
    CrowdIntroductionSmall,
    WindowsNews,
    WindowsNewsEdit,
    WindowsTags,
    GardenHeader,
    GardenOther,
    GardenOtherEdit,
    WelfareModule1,
    WelfareEnvironment,
    WindowsTalent, GardenExternal, SeaPurchaseIntroduction, SeaPurchaseGlobal, CrossBorderIntroduction, JoinUsJobInfo
} from "@/pages";
import {
    UserOutlined,
    SettingOutlined,
    HomeOutlined
} from "@ant-design/icons";
import MyIcon from '../components/MyIcon'
import {WindowsTalentEdit} from "../pages";

const commonRoutes = [
    {
        pathName: '/login',
        component: Login
    },
    {
        pathName: '/404',
        component: NotFound
    }

]

const privateRoutes = [
    {
        pathName: '/admin',
        component: Admin,
        name: "首页",
        icon: <HomeOutlined/>,
        isFirst: true,
        id: 1,
    },
    {
        pathName: '/admin/index',
        name: "首页管理",
        icon: <MyIcon type={'icon-shouye'}/>,
        component: null,
        isFirst: true,
        id: 2,
        children: [
            {
                pathName: '/admin/index/strategy',
                name: "至名战略",
                icon: <MyIcon type={'icon-shishizhanlve'}/>,
                component: IndexStrategy
            },
            {
                pathName: '/admin/index/course',
                name: "发展历程",
                icon: <MyIcon type={'icon-shehuijingli'}/>,
                component: IndexCourse
            },
            {
                pathName: '/admin/index/partner',
                name: "品牌伙伴",
                icon: <MyIcon type={'icon-hezuohuoban1'}/>,
                component: IndexPartner
            },
            {
                pathName: '/admin/index/partnerSmall',
                name: "合作伙伴-移动端",
                icon: <MyIcon type={'icon-hezuohuoban1'}/>,
                component: IndexPartnerSmall
            },

            {
                pathName: '/admin/index/data',
                name: "至名数据",
                icon: <MyIcon type={'icon-shuju'}/>,
                component: IndexData
            },
        ]
    },
    {
        pathName: '/admin/walkInZm',
        name: "走进至名",
        icon: <MyIcon type={'icon-zoujin'}/>,
        component: null,
        isFirst: true,
        id: 3,
        children: [
            {
                pathName: '/admin/walkInZm/introduction',
                name: "公司简介",
                icon: <MyIcon type={'icon-qiyejianjie'}/>,
                component: WalkInIntroduction
            },
            {
                pathName: '/admin/walkInZm/culture',
                name: "企业文化",
                icon: <MyIcon type={'icon-qiyewenhua'}/>,
                component: WalkInCulture
            },
            {
                pathName: '/admin/walkInZm/team',
                name: "核心竞争力",
                icon: <MyIcon type={'icon-tuandui'}/>,
                component: WalkInTeam
            },
        ]
    },
    {
        pathName: '/admin/healthy',
        name: "至名健康",
        icon: <MyIcon type={'icon-jiankang'}/>,
        component: null,
        isFirst: true,
        id: 4,
        children: [
            {
                pathName: '/admin/healthy/introduction',
                name: "至名健康简介",
                icon: <MyIcon type={'icon-jianjie-03'}/>,
                component: HealthyIntroduction
            },
            {
                pathName: '/admin/healthy/cover',
                name: "全平台覆盖",
                icon: <MyIcon type={'icon-fugai'}/>,
                component: HealthyCover
            },
            {
                pathName: '/admin/healthy/advantage',
                name: "核心优势",
                icon: <MyIcon type={'icon-youshi'}/>,
                component: HealthyAdvantage
            },
        ]
    },
    {
        pathName: '/admin/seaPurchase',
        name: "至名海购",
        icon: <MyIcon type={'icon-yuehaigou'}/>,
        component: null,
        isFirst: true,
        id: 5,
        children: [
            {
                pathName: '/admin/seaPurchase/introduction',
                name: '关于我们',
                icon: <MyIcon type={'icon-youshi'}/>,
                component: SeaPurchaseIntroduction
            },
            {
                pathName: '/admin/seaPurchase/global',
                name: '全球',
                icon: <MyIcon type={'icon-youshi'}/>,
                component: SeaPurchaseGlobal
            }
        ]
    },
    {
        pathName: '/admin/crossBorder',
        name: "至名跨境",
        icon: <MyIcon type={'icon-quanqiukuajing'}/>,
        component: null,
        isFirst: true,
        id: 6,
        children: [
            {
                pathName: '/admin/crossBorder/introduction',
                name: '关于我们',
                icon: <MyIcon type={'icon-youshi'}/>,
                component: CrossBorderIntroduction
            },
        ]
    },
    {
        pathName: '/admin/crowd',
        name: "君达电商",
        icon: <MyIcon type={'icon-chuangyejiuye'}/>,
        component: null,
        isFirst: true,
        id: 7,
        children: [
            {
                pathName: '/admin/crowd/pioneer',
                name: "启动创业",
                icon: <MyIcon type={'icon-qidong'}/>,
                component: CrowdPioneer
            },
            {
                pathName: '/admin/crowd/policy',
                name: "三大政策",
                icon: <MyIcon type={'icon-zhengce'}/>,
                component: CrowdPolicy
            },
            {
                pathName: '/admin/crowd/brand',
                name: "品牌孵化",
                icon: <MyIcon type={'icon-xiangmufuhua'}/>,
                component: CrowdBrand
            },
            {
                pathName: '/admin/crowd/introductionSmall',
                name: "简介-移动端",
                icon: <MyIcon type={'icon-xiangmujianjie'}/>,
                component: CrowdIntroductionSmall
            },
        ]
    },

    {
        pathName: '/admin/zmWindows',
        name: "至名之窗",
        icon: <MyIcon type={'icon-chuanghu'}/>,
        component: null,
        isFirst: true,
        id: 8,
        children: [
            {
                pathName: '/admin/zmWindows/news',
                name: "至名新闻",
                icon: <MyIcon type={'icon-xiangmujianjie'}/>,
                component: WindowsNews
            },
            {
                pathName: '/admin/zmWindows/newsEdit',
                name: "编辑新闻",
                component: WindowsNewsEdit,
                hidden:true
            },
            {
                pathName: '/admin/zmWindows/talent',
                name: "至名风华",
                icon: <MyIcon type={'icon-xiangmujianjie'}/>,
                component: WindowsTalent
            },
            {
                pathName: '/admin/zmWindows/talentEdit',
                name: "编辑新闻",
                component: WindowsTalentEdit,
                hidden:true
            },
            {
                pathName: '/admin/zmWindows/tags',
                name: "标签管理",
                icon: <MyIcon type={'icon-biaoqian'}/>,
                component: WindowsTags,
            },
        ]
    },
    {
        pathName: '/admin/garden',
        name: "党建园地",
        icon: <MyIcon type={'icon-dangjian'}/>,
        component: null,
        isFirst: true,
        id: 9,
        children: [
            {
                pathName: '/admin/garden/header',
                name: "头部文章",
                icon: <MyIcon type={'icon-biaoqian'}/>,
                component: GardenHeader,
            },
            {
                pathName: '/admin/garden/other',
                name: "其他文章",
                icon: <MyIcon type={'icon-biaoqian'}/>,
                component: GardenOther,
            },
            {
                pathName: '/admin/garden/external',
                name: "外部链接",
                icon: <MyIcon type={'icon-biaoqian'}/>,
                component: GardenExternal,
            },
            {
                pathName: '/admin/garden/otherEdit',
                name: "编辑新闻",
                component: GardenOtherEdit,
                hidden:true
            },
        ]
    },
    {
        pathName: '/admin/joinUs/welfare',
        name: "福利待遇",
        icon: <MyIcon type={'icon-wodefuli'}/>,
        component: null,
        isFirst: true,
        id: 10,
        children: [
            {
                pathName: '/admin/joinUs/welfare/module1',
                name: "福利待遇",
                icon: <MyIcon type={'icon-biaoqian'}/>,
                component: WelfareModule1,
            },
            {
                pathName: '/admin/joinUs/welfare/environment',
                name: "公司环境",
                icon: <MyIcon type={'icon-biaoqian'}/>,
                component: WelfareEnvironment,
            },
        ]
    },
    {
        pathName: '/admin/joinUs/jobInfo',
        name: "岗位信息",
        icon: <MyIcon type={'icon-gangwei'}/>,
        component: null,
        isFirst: true,
        id: 11,
        children: [
            {
                pathName: '/admin/joinUs/jobInfo/link',
                name: "岗位信息",
                icon: <MyIcon type={'icon-biaoqian'}/>,
                component: JoinUsJobInfo,
            },
        ]
    },

    {
        pathName: '/admin/user',
        name: "用户管理",
        icon: <UserOutlined/>,
        component: null,
        isFirst: true,
        id: 2,
        children: [
            {
                pathName: '/admin/user/user',
                name: "用户管理",
                icon: <UserOutlined/>,
                component: User
            },
            {
                pathName: '/admin/user/role',
                name: "角色管理",
                icon: <MyIcon type={'icon-jiaose'}/>,
                component: Role
            },
            // {
            //     pathName: '/admin/user/watermark',
            //     name: "标识码管理",
            //     icon: <TagsOutlined/>,
            //     component: Watermark
            // }
        ]
    },
    {
        pathName: '/admin/system',
        component: System,
        name: "系统管理",
        icon: <SettingOutlined/>,
        isFirst: true,
        id: 10,
    },
]

const hideRoutes = [
    {
        pathName: '/admin/individual',
        component: Individual,
    },

]

export {
    commonRoutes,
    privateRoutes,
    hideRoutes
}
