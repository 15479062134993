import React, {Component} from 'react';
import {Layout} from "antd";

const {Footer} = Layout;

class FooterPage extends Component {
    render() {
        return (
            <Footer style={{textAlign: 'center'}}>至名集团 ©2022 </Footer>
        );
    }
}

export default FooterPage;
