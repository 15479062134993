import Loadable from 'react-loadable'
import Loading from '../components/Loading'

// 路由懒加载

const Login = Loadable({
    loader: () => import("./login"),
    loading: Loading,
})
const System = Loadable({
    loader: () => import("./system"),
    loading: Loading,
})
const Admin = Loadable({
    loader: () => import("./admin"),
    loading: Loading,
})
const NotFound = Loadable({
    loader: () => import("./notFound"),
    loading: Loading,
})
const User = Loadable({
    loader: () => import("./user/user"),
    loading: Loading,
})
const Watermark = Loadable({
    loader: () => import("./user/watermarkCode"),
    loading: Loading,
})

const Role = Loadable({
    loader: () => import("./user/role"),
    loading: Loading,
})

const Individual = Loadable({
    loader: () => import("./individual"),
    loading: Loading,
})
const IndexStrategy = Loadable({
    loader: () => import("./indexMng/strategy"),
    loading: Loading,
})

const IndexCourse = Loadable({
    loader: () => import("./indexMng/course"),
    loading: Loading,
})
const IndexData = Loadable({
    loader: () => import("./indexMng/data"),
    loading: Loading,
})
const IndexPartner = Loadable({
    loader: () => import("./indexMng/partner"),
    loading: Loading,
})
const IndexPartnerSmall = Loadable({
    loader: () => import("./indexMng/partnerSmall"),
    loading: Loading,
})

const WalkInIntroduction = Loadable({
    loader: () => import("./walkInZm/Introduction"),
    loading: Loading,
})
const WalkInCulture = Loadable({
    loader: () => import("./walkInZm/culture"),
    loading: Loading,
})
const WalkInTeam = Loadable({
    loader: () => import("./walkInZm/team"),
    loading: Loading,
})

const HealthyIntroduction = Loadable({
    loader: () => import("./healthy/introduction"),
    loading: Loading,
})
const HealthyCover = Loadable({
    loader: () => import("./healthy/cover"),
    loading: Loading,
})
const HealthyAdvantage = Loadable({
    loader: () => import("./healthy/advantage"),
    loading: Loading,
})
const CrowdPioneer = Loadable({
    loader: () => import("./crowd/pioneer"),
    loading: Loading,
})

const CrowdPolicy = Loadable({
    loader: () => import("./crowd/policy"),
    loading: Loading,
})
const CrowdBrand = Loadable({
    loader: () => import("./crowd/brand"),
    loading: Loading,
})
const CrowdIntroductionSmall = Loadable({
    loader: () => import("./crowd/introductionSmall"),
    loading: Loading,
})

const WindowsNews = Loadable({
    loader: () => import("./zmWindows/news"),
    loading: Loading,
})
const WindowsTalent = Loadable({
    loader: () => import("./zmWindows/talent"),
    loading: Loading,
})

const WindowsNewsEdit = Loadable({
    loader: () => import("./zmWindows/news/edit"),
    loading: Loading,
})
const WindowsTalentEdit = Loadable({
    loader: () => import("./zmWindows/talent/edit"),
    loading: Loading,
})
const WindowsTags = Loadable({
    loader: () => import("./zmWindows/tags"),
    loading: Loading,
})

const GardenHeader = Loadable({
    loader: () => import("./garden/header"),
    loading: Loading,
})
const GardenOther = Loadable({
    loader: () => import("./garden/other"),
    loading: Loading,
})
const GardenExternal = Loadable({
    loader: () => import("./garden/external"),
    loading: Loading,
})
const GardenOtherEdit = Loadable({
    loader: () => import("./garden/other/edit"),
    loading: Loading,
})

const WelfareModule1 = Loadable({
    loader: () => import("./joinUs/welfare/welfare"),
    loading: Loading,
})
const WelfareEnvironment = Loadable({
    loader: () => import("./joinUs/welfare/environment"),
    loading: Loading,
})
const SeaPurchaseIntroduction =Loadable({
    loader: () => import("./seaPurchase/introduction"),
    loading: Loading,
})

const SeaPurchaseGlobal =Loadable({
    loader: () => import("./seaPurchase/global"),
    loading: Loading,
})
const CrossBorderIntroduction =Loadable({
    loader: () => import("./crossBorder/introduction"),
    loading: Loading,
})
const JoinUsJobInfo =Loadable({
    loader: () => import("./joinUs/jobInfo"),
    loading: Loading,
})

export {
    Login,
    System,
    Admin,
    NotFound,
    User,
    Watermark,
    Role,
    Individual,
    IndexStrategy,
    IndexCourse,
    IndexData,
    IndexPartner,
    WalkInIntroduction,
    IndexPartnerSmall,
    WalkInCulture,
    WalkInTeam,
    HealthyIntroduction,
    HealthyCover,
    HealthyAdvantage,
    CrowdPioneer,
    CrowdPolicy,
    CrowdBrand,
    CrowdIntroductionSmall,
    WindowsNews,
    WindowsNewsEdit,
    WindowsTags,
    GardenHeader,
    GardenOther,
    GardenOtherEdit,
    WelfareModule1,
    WelfareEnvironment,
    WindowsTalent,
    GardenExternal,
    SeaPurchaseIntroduction,
    SeaPurchaseGlobal,
    CrossBorderIntroduction,
    JoinUsJobInfo,
    WindowsTalentEdit
}
